import React from "react";
import { FiInstagram, FiYoutube } from 'react-icons/fi';
import sisger from '../../assets/logo-sisger-h-branca.png';
import logomarca from '../../assets/logomarca_telessaude.png';
import logosaude from '../../assets/logo_secretaria.png';
import './style.css';

export default function Footer () {
    return (
        <div>
            <footer className='footer'>
                <div className="contact-info">
                <p>Número para contato: (81) 3184-0239</p>
                <br />
                <p>E-mail para contato: telessaude.rvs@gmail.com</p>
                </div>
                <div className="social-icons">
                <a href="https://www.instagram.com/telessaudesespe/" target="_blank" rel="noopener noreferrer">
                    <FiInstagram size={30} />
                </a>
                <a href="https://www.youtube.com/c/TelessaudeSESPE" target="_blank" rel="noopener noreferrer">
                    <FiYoutube size={30} />
                </a>
                </div>
                <div className="logo">
                <img src={sisger} alt="Logo do Sistema de Gerenciamento de Reunião" className="sisger" />
                <img src={logomarca} alt="Logo da Diretoria Geral de Telessaúde" className="logomarca" />
                <img src={logosaude} alt="Logo da Secretaria Estadual de Saúde de Pernambuco" className="logosaude" />
                </div>
                <div className="footer__copyright">
                <p className="footer__copyright">
                    © 2024 Diretoria Geral de Telessaúde de Pernambuco. Todos os direitos reservados.
                </p>
                </div>
            </footer>
        </div>
    )
}