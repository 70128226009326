import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ModalAgenda from '../../components/ModalAgenda';
import barra from '../../assets/barra-agenda.jpg';
import Footer from '../../components/Footer';
import './style.css';

const Agenda = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes/confirmadas');
      setMeetings(response.data);
    } catch (error) {
      console.error('Erro ao buscar reuniões:', error);
    }
  };

  const filteredMeetings = meetings.filter(
    meeting => meeting.localEvento === 'Sala do Telessaude' || meeting.localEvento === 'Sala + Link'
  ).map(meeting => ({
    title: meeting.tituloReuniao,
    start: `${format(new Date(meeting.dataReuniao), 'yyyy-MM-dd')}T${meeting.horarioInicio}`,
  }));

  const handleEventClick = ({ event }) => {
    const selectedEvent = meetings.find(meeting => meeting.tituloReuniao === event.title);
    setSelectedMeeting(selectedEvent);
  };

  const closeModal = () => {
    setSelectedMeeting(null);
  }; 

  return (
    <>
    <img src={barra} alt="Barra de uma agenda com números" className="barra"/>
    <h3 className="agenda-descricao">Agenda de disponibilidade da sala de reunião da Diretoria Geral de Telessaúde</h3>
    <div className="agenda-container">
      <div className="calendar-container">
        <FullCalendar 
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView="listWeek"
          events={filteredMeetings}
          locale={ptLocale}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          eventClick={handleEventClick} 
        />
      </div>
      <ModalAgenda {...selectedMeeting} close={closeModal} />
    </div>
    <Footer />
    </>
  );
};

export default Agenda;
