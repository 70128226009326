import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import './style.css';
import logo from '../../assets/logo-sisger-v-branca.png';
import logomarca from '../../assets/logomarca_telessaude.png';
import logosaude from '../../assets/logo_secretaria.png';

export default function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signin, user } = useAuth(); // Adicionando user para verificar se o usuário está autenticado
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); 

    if (!email || !password) {
      setError("Preencha todos os campos");
      return;
    }

    try {
      await signin(email, password);
    } catch(error) {
      setError(error.message);
    };
  };

  // Redirecionamento quando o usuário estiver autenticado
  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logo do sistema de agendamentos" />
        </div>

        <form onSubmit={handleLogin}>
          <h1>Entrar</h1>
          <input
            type="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit">Acessar</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="logos">
        <img src={logomarca} alt="Logo da Diretoria Geral de Telessaúde" />
        <img src={logosaude} alt="Logo da Secretaria Estadual de Saúde de Pernambuco" className="logosaude" />
      </div>
    </div>
  );
}
