import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';

import Signin from '../pages/Signin';
import Dashboard from '../pages/Dashboard';
import Calendar from '../pages/Calendar';
import Confirmadas from '../pages/Confirmadas';
import Canceladas from '../pages/Canceladas';
import Salas from '../pages/Salas';
import Profile from '../pages/Profile';
import Forms from '../pages/Forms';
import Agenda from '../pages/Agenda';

import Private from './Private';

function RoutesApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/home" element={<Private><Dashboard /></Private>} />
        <Route path="/calendar" element={<Private><Calendar /></Private>} />
        <Route path="/confirmed" element={<Private><Confirmadas /></Private>} />
        <Route path="/canceled" element={<Private><Canceladas /></Private>} />
        <Route path="/salas" element={<Private><Salas /></Private>} />
        <Route path="/profile" element={<Private><Profile /></Private>} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/agenda" element={<Agenda />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesApp;
