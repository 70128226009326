import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { format } from 'date-fns';
import axios from 'axios';
import './style.css';
import { FiCalendar } from 'react-icons/fi';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Modal from '../../components/Modal';

export default function Calendar() {
  const [allMeetings, setAllMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterType, setFilterType] = useState('all');
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  useEffect(() => {
    fetchMeetings();
  }, []);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes/confirmadas');
      setAllMeetings(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao buscar reuniões:', error);
      setIsLoading(false);
    }
  };

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };

  const allEvents = allMeetings.map(meeting => ({
    title: meeting.tituloReuniao,
    start: `${format(new Date(meeting.dataReuniao), 'yyyy-MM-dd')}T${meeting.horarioInicio}`,
  }));

  const availabilityEvents = allMeetings.filter(meeting => meeting.localEvento === 'Sala do Telessaude' || meeting.localEvento === 'Sala + Link').map(meeting => ({
    title: meeting.tituloReuniao,
    start: `${format(new Date(meeting.dataReuniao), 'yyyy-MM-dd')}T${meeting.horarioInicio}`,
  }));

  const events = filterType === 'all' ? allEvents : availabilityEvents;

  const handleEventClick = ({ event }) => {
    const selectedEvent = allMeetings.find(meeting => meeting.tituloReuniao === event.title);
    setSelectedMeeting(selectedEvent);
  };

  const closeModal = () => {
    setSelectedMeeting(null);
  }; 

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Calendário">
          <FiCalendar size={25} />
        </Title>
        <div className="filter">
          <button className={filterType === 'all' ? 'active' : ''} onClick={() => handleFilterChange('all')}>Todas as reuniões</button>
          <button className={filterType === 'availability' ? 'active' : ''} onClick={() => handleFilterChange('availability')}>Disponibilidade da sala de reunião</button>
        </div>
        {isLoading ? (
          <p>Carregando...</p>
        ) : (
          <div style={{ height: '400px', margin: '30px 0'}}>
            <FullCalendar 
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
              initialView="dayGridMonth"
              events={events}
              locale={ptLocale}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
              }}
              eventClick={handleEventClick} 
            />
          </div>
        )}
        <Modal {...selectedMeeting} close={closeModal} />
      </div>
    </div>
  );
}
