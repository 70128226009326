import { format } from 'date-fns';
import './style.css';
import { FiX } from 'react-icons/fi';

export default function ModalAgenda({ close, id, tituloReuniao, localEvento, nomeResponsavel, telefone, email, areaTecnica, dataReuniao, horarioInicio, horarioFim, linkReuniao }) {
    if (!id) {
        return null;
    }

  return (
    <div className="modal">
      <div className="container">
        <button className="close" onClick={close}>
          <FiX size={25} color="#FFF" />
          Fechar
        </button>

        <main>
          <h2>Detalhes da solicitação</h2>

          <div className="row">
            <span>
              Id: <i>{id}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Título: <i>{tituloReuniao}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Local: <i>{localEvento}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Responsável: <i>{nomeResponsavel}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Telefone: <i>{telefone}</i>
            </span>
          </div>

          <div className="row">
            <span>
              E-mail: <i>{email}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Área: <i>{areaTecnica}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Data: <i>{format(new Date(dataReuniao), 'dd/MM/yyyy')}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Início: <i>{horarioInicio}</i>
            </span>
            <span>
              Fim: <i>{horarioFim}</i>
            </span>
          </div>

          <div className="row">
            <span>
              Link: <i>{linkReuniao}</i>
            </span>
          </div>

        </main>
      </div>
    </div>
  );
}
