// Código do contexts.js
import { createContext, useEffect, useState } from "react";
import axios from "../api/axios";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.get("https://sisger-homo-telesaude.saude.pe.gov.br/api/user", { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          setUser(response.data.user);
          setLoading(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            console.error("Token de autorização ausente ou inválido");
            // Realizar ações alternativas, como redirecionamento para a página de login
          } else {
            console.error("Erro interno do servidor:", error);
          }
          setUser(null);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const signin = async (email, senha) => {
    try {
      const response = await axios.post("https://sisger-homo-telesaude.saude.pe.gov.br/api/adm/login", { email, senha });
      const { token } = response.data;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const userResponse = await axios.get("https://sisger-homo-telesaude.saude.pe.gov.br/api/user");
      setUser(userResponse.data.user);
      return userResponse.data.user;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw new Error("E-mail ou senha incorretos");
    }
  };

  const signout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ signed: !!user, user, loading, signin, signout }}>
      {children}
    </AuthContext.Provider>
  );
};
