import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import './style.css';
import axios from 'axios';
import { toast as showToast } from 'react-toastify';

export default function ModalEdit({ close, id, solicitacao, onSave }) {
  const [tituloReuniao, setTituloReuniao] = useState(solicitacao.tituloReuniao || '');
  const [localEvento, setLocalEvento] = useState(solicitacao.localEvento || '');
  const [nomeResponsavel, setNomeResponsavel] = useState(solicitacao.nomeResponsavel || '');
  const [telefone, setTelefone] = useState(solicitacao.telefone || '');
  const [email, setEmail] = useState(solicitacao.email || '');
  const [areaTecnica, setAreaTecnica] = useState(solicitacao.areaTecnica || '');

  const formattedDataReuniao = solicitacao.dataReuniao
    ? new Date(solicitacao.dataReuniao).toISOString().split('T')[0]
    : '';
  const [dataReuniao, setDataReuniao] = useState(formattedDataReuniao);
  const [horarioInicio, setHorarioInicio] = useState(solicitacao.horarioInicio || '');
  const [horarioFim, setHorarioFim] = useState(solicitacao.horarioFim || '');
  const [linkReuniao, setLinkReuniao] = useState(solicitacao.linkReuniao || '');
  const [qtdParticipantes, setQtdParticipantes] = useState(solicitacao.qtdParticipantes || '');


  const handleSave = async () => {
    try {
      const { id, ...rest } = solicitacao;
      const updatedSolicitacao = {
        ...rest,
        tituloReuniao,
        localEvento,
        nomeResponsavel,
        telefone,
        email,
        areaTecnica,
        dataReuniao,
        horarioInicio,
        horarioFim,
        linkReuniao,
        qtdParticipantes
      };
  
      await axios.put(`https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes/${id}`, updatedSolicitacao);
  
      showToast.success('Solicitação atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          window.location.reload();
        }
      });
  
      // Atualize as solicitações após a atualização
      onSave(id, updatedSolicitacao);
      close();
    } catch (error) {
      console.error('Erro ao atualizar a solicitação:', error);
    }
  };
  

  return (
    <div className="modal-edit">
      <div className="modal-content">
        <button className="close-button" onClick={close}>
          <FiX size={20} />
        </button>
        <h2>Editar Solicitação</h2>
        <div className="modal-body">
          <label>
            Título da Reunião:
            <input type="text" value={tituloReuniao} onChange={(e) => setTituloReuniao(e.target.value)} />
          </label>
          <label>
            Local:
            <select value={localEvento} onChange={(e) => setLocalEvento(e.target.value)}>
              <option value="Link de Transmissao">Link de Transmissão (Sala virtual)</option>
              <option value="Sala do Telessaude">Sala de Reunião da Telessaúde (Sala física)</option>
              <option value="Sala + Link">Sala de Reunião da Telessaúde + Link de Transmissão</option>
              <option value="Auditorio">Link de transmissão para evento no Auditório da SES/PE</option>
            </select>
          </label>
          <label>
            Responsável:
            <input type="text" value={nomeResponsavel} onChange={(e) => setNomeResponsavel(e.target.value)} />
          </label>
          <label>
            Telefone:
            <input type="text" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
          </label>
          <label>
            E-mail:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <label>
            Área:
            <input type="text" value={areaTecnica} onChange={(e) => setAreaTecnica(e.target.value)} />
          </label>
          <label>
            Data:
            <input type="date" value={dataReuniao} onChange={(e) => setDataReuniao(e.target.value)} />
          </label>
          <label>
            Início:
            <input type="time" value={horarioInicio} onChange={(e) => setHorarioInicio(e.target.value)} />
          </label>
          <label>
            Fim:
            <input type="time" value={horarioFim} onChange={(e) => setHorarioFim(e.target.value)} />
          </label>
          <label>
            Link da Reunião:
            <input type="text" value={linkReuniao} onChange={(e) => setLinkReuniao(e.target.value)} />
          </label>
          <label>
            Quantidade de Participantes:
            <input type="number" value={qtdParticipantes} onChange={(e) => setQtdParticipantes(e.target.value)} />
          </label>
        </div>
        <div className="modal-footer">
          <button className="save-button" onClick={handleSave}>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
