import React, { useState } from 'react';
import './style.css'
import axios from 'axios';
import { TextField, Button, Container, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../components/Footer';
import barra from '../../assets/barra-agenda.jpg';

const Forms = () => {
  const [formData, setFormData] = useState({
    tituloReuniao: '',
    nomeResponsavel: '',
    telefone: '',
    email: '',
    areaTecnica: '',
    dataReuniao: '',
    horarioInicio: '',
    horarioFim: '',
    gravar: false,
    localEvento: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitarReuniao', formData);
      
      toast.success('Formulário preenchido com sucesso!', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      
      setFormData({
        tituloReuniao: '',
        nomeResponsavel: '',
        telefone: '',
        email: '',
        areaTecnica: '',
        dataReuniao: '',
        horarioInicio: '',
        horarioFim: '',
        gravar: false,
        localEvento: '',
      });
    } catch(error) {
      console.error('Erro durante a solicitação:', error);

      toast.error('Erro ao enviar o formulário. Por favor, tente novamente.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
    <img src={barra} alt="Barra de uma agenda com números" className="barra"/>
    <Container maxWidth="md" className="container">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="form">
        <span className="aviso">
          AGENDAMENTOS NO AUDITÓRIO:
          <br />
          <br />
          ATENÇÃO: PARA AGENDAMENTO DE REUNIÕES NO AUDITÓRIO COM TRANSMISSÃO, ENTRAR EM CONTATO COM O TELESSAÚDE COM ANTECEDÊNCIA PARA VERIFICAR A VIABILIDADE TÉCNICA.
          <br />
          <br />
          AGENDAMENTO NA SALA DO TELESSAÚDE:
          <br />
          <br />
          PARA AGENDAMENTOS NA SALA DO TELESSAÚDE, CONSULTAR A DISPONIBILIDADE DE SALA <a href="/agenda" target="_blank" rel="noopener noreferrer" className="link">CLICANDO AQUI</a> OU LIGAR PARA O RAMAL: 0239.
        </span>

        <TextField
          label="Título da Reunião"
          name="tituloReuniao"
          fullWidth
          required
          value={formData.tituloReuniao}
          onChange={handleChange}
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="localEvento-label">Local do Evento</InputLabel>
          <Select
            labelId="localEvento-label"
            id="localEvento"
            name="localEvento"
            required
            value={formData.localEvento}
            onChange={handleChange}
          >
            <MenuItem value="Link de Transmissao">Link de Transmissão (Sala virtual)</MenuItem>
            <MenuItem value="Sala do Telessaude">Sala de Reunião da Telessaúde (Sala física)</MenuItem>
            <MenuItem value="Sala + Link">Sala de Reunião da Telessaúde + Link de Transmissão</MenuItem>
            <MenuItem value="Auditorio">Link de transmissão para evento no Auditório da SES/PE</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Nome da Pessoa Responsável"
          name="nomeResponsavel"
          fullWidth
          required
          value={formData.nomeResponsavel}
          onChange={handleChange}
          margin="normal"
        />

        <TextField
          label="Telefone para contato"
          type="text"
          name="telefone"
          fullWidth
          required
          value={formData.telefone}
          onChange={handleChange}
          margin="normal"
        />

        <TextField
          label="E-mail"
          type="email"
          name="email"
          fullWidth
          required
          value={formData.email}
          onChange={handleChange}
          margin="normal"
        />

        <TextField
          label="Área Técnica Solicitante"
          name="areaTecnica"
          fullWidth
          required
          value={formData.areaTecnica}
          onChange={handleChange}
          margin="normal"
        />

        <TextField
          label="Data da Reunião"
          type="date"
          name="dataReuniao"
          fullWidth
          required
          value={formData.dataReuniao}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />

        <TextField
          label="Horário de Início"
          type="time"
          name="horarioInicio"
          fullWidth
          required
          value={formData.horarioInicio}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
        <TextField
          label="Horário Final"
          type="time"
          name="horarioFim"
          fullWidth
          required
          value={formData.horarioFim}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                name="gravar"
                checked={formData.gravar}
                onChange={handleChange}
              />
            }
            label="Deverá ser gravada?"
          />
        </FormControl>

        <span className="warning">
          ATENÇÃO:
          <br />
          <br />
          APÓS O ENVIO DA SOLICITAÇÃO, AGUARDAR A CONFIRMAÇÃO ENVIADA PELO SISTEMA, NO PRAZO DE ATÉ 24 HORAS DURANTE O EXPEDIENTE.
        </span>

        

        <Button type="submit" variant="contained" color="primary" className="button">
          Enviar Solicitação
        </Button>
      </form>
    </Container>

    <Footer />
      </>
  );
};

export default Forms;
