
import { Link, useNavigate } from 'react-router-dom'
import { FiHome, FiCheck, FiXCircle, FiFileText, FiPlus, FiCalendar, FiLogOut} from 'react-icons/fi'
import './style.css';
import useAuth from '../../hooks/useAuth';

export default function Header(){
  const { signout } = useAuth();
  const navigate = useNavigate()

  const handleLogout = () => {
    signout();
    navigate('/');
  }

  return(
    <div className="sidebar">
      <div>
       
      </div>

      <Link to="/home">
        <FiHome color="#FFF" size={24} />
        Solicitações
      </Link>

      <Link to="/calendar">
        <FiCalendar color="#FFF" size={24} />
        Calendário
      </Link>

      <Link to="/confirmed">
        <FiCheck color="#FFF" size={24} />
        Confirmadas
      </Link>

      <Link to="/canceled">
        <FiXCircle color="#FFF" size={24} />
        Canceladas
      </Link>

      <Link to="/forms">
        <FiPlus color="#FFF" size={24} />
        Agendar
      </Link>

      <Link to="/salas">
        <FiFileText color="#FFF" size={24} />
        Salas
      </Link>

      <Link to="/" onClick={handleLogout}>
        <FiLogOut color="#FFF" size={24} />
        Sair
      </Link>

    </div>
  )
}