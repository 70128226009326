import React, { useState } from 'react'
import Header from '../../components/Header'
import Title from '../../components/Title'
import './style.css'

import { FiFileText } from "react-icons/fi";

export default function Salas(){
    const [itensVisiveis, setItensVisiveis] = useState(10);
    const todosItens = [
        {nome: "Reunião DGT", link:"https://conferenciaweb.rnp.br/rute/reuniao-dgt"},
        {nome: "Reunião GAB", link:"https://conferenciaweb.rnp.br/rute/gab-sespe"},
        {nome: "Grupo Técnico de óbitos Infantil Fetal", link:"https://conferenciaweb.rnp.br/rute/grupo-tecnico-de-obitos-infantil-fetal"},
        {nome: "SES - Vigilância Em Saúde", link:"https://conferenciaweb.rnp.br/rute/ses-vigilancia-em-saude"},
        {nome: "SESPE-CIB", link:"https://conferenciaweb.rnp.br/rute/sespe-cib"},
        {nome: "Saúde Prisional", link:"https://conferenciaweb.rnp.br/rute/saude-prisional"},
        {nome: "DGAP", link:"https://conferenciaweb.rnp.br/rute/dgap"},
        {nome: "Reunião CES", link:"https://conferenciaweb.rnp.br/rute/reuniao-ces"},
        {nome: "SESPE", link:"https://conferenciaweb.rnp.br/rute/sespe"},
        {nome: "Teleducação SES-PE", link:"https://conferenciaweb.rnp.br/rute/teleducacao-ses-pe"},
        {nome: "Saúde LGBT", link:"https://conferenciaweb.rnp.br/rute/saude-lgbt"},
        {nome: "Sig Economia da Saúde", link:"https://conferenciaweb.rnp.br/rute/sigeconomiadasaude"},
        {nome: "SESPE-GASAM", link:"https://conferenciaweb.rnp.br/rute/sespe-gasam"},
        {nome: "Reunião NES-HOSP", link:"https://conferenciaweb.rnp.br/rute/nes-hosp"},
        {nome: "Reunião-CV", link:"https://conferenciaweb.rnp.br/rute/reuniao-cv"},
        {nome: "Grupo de Pernambuco do subcomitê de epidemiologia", link:"https://conferenciaweb.rnp.br/rute/grupo-de-pernambuco-do-subcomite-de-epidemiologia"},
        {nome: "SESPE-PRI", link:"https://conferenciaweb.rnp.br/rute/sespe-pri"},
        {nome: "TEA TELECONSULTORIAS", link:"https://conferenciaweb.rnp.br/rute/tea-teleconsultorias"},
        {nome: "Controle Interno - SES", link:"https://conferenciaweb.rnp.br/rute/controle-interno-ses"}
    ]
    const exibirMaisItens = () => {
        setItensVisiveis(itensVisiveis + 10);
    };

    return(
        <div>
            <Header />

        <div className="content">
            <Title name="Salas Virtuais">
                <FiFileText size={25} />
            </Title>

        <>
        <table className='ses'>
            <thead>
                <tr>
                    <th scope="col">SES</th>
                    <th scope="col">Link</th>
                </tr>
            </thead>
            <tbody>
            {todosItens.slice(0, itensVisiveis).map((item, index) => (
                <tr key={index}>
                    <td data-label="Nome da sala">{item.nome}</td>
                    <td data-label="Link">{item.link}</td>
                </tr>
                ))}
            </tbody>
        </table>
        {itensVisiveis < todosItens.length && (
            <button className="btn-more" onClick={exibirMaisItens}>Exibir mais</button>
        )}
        
        </>    

        <>
        <table className='geres'>
            <thead>
                <tr>
                    <th scope="col">GERES</th>
                    <th scope="col">Link</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td data-label="Nome da sala">I Geres - Recife</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/i-geres-recife</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">II Geres - Limoeiro</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/ii-geres-limoeiro</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">III Geres - Palmares</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/iii-geres-palmares</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">IV Geres - Caruaru</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/iv-geres-caruaru</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">V Geres - Garanhuns</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/v-geres-garanhuns</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">VI Geres - Arcoverde</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/vi-geres-arcoverde</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">VII Geres - Salgueiro</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/vii-geres-salgueiro</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">VIII Geres - Petrolina</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/viii-geres-petrolina</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">IX Geres - Ouricuri</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/ix-geres-ouricuri</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">X Geres - Afogados da Ingazeira</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/x-geres-afogados-da-ingazeira</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">XI Geres - Serra Talhada</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/xi-geres-serra-talhada</td>
                </tr>
                <tr>
                    <td data-label="Nome da sala">XII Geres - Goiana</td>
                    <td data-label="Link">https://conferenciaweb.rnp.br/rute/xii-geres-goiana</td>
                </tr> 
            </tbody>
        </table>


        </>    
        </div>

        </div>

        
    )
}