import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import Modal from '../../components/Modal';
import ModalEdit from '../../components/ModalEdit';
import axios from '../../api/axios';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FiHome, FiPlus, FiSearch, FiEdit2, FiCheck, FiXSquare, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const PAGE_SIZE = 20;

export default function Dashboard() {
  const [showPostModal, setShowPostModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentSolicitacao, setCurrentSolicitacao] = useState(null);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [filteredSolicitations, setFilteredSolicitations] = useState([]);
  const [showCancelled, setShowCancelled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSolicitacoes = async () => {
    try {
      const response = await axios.get('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes');
      let sortedSolicitacoes = response.data.sort((a, b) => new Date(b.dataReuniao) - new Date(a.dataReuniao));
      if (!showCancelled){
        sortedSolicitacoes = sortedSolicitacoes.filter(solicitacao => solicitacao.status !== 'Cancelada');
      }
      setSolicitacoes(sortedSolicitacoes);
      setFilteredSolicitations([]); 
    } catch (error) {
      console.error('Erro ao buscar solicitações:', error);
    }
  };

  const handleEdit = async (id, updatedData) => {
    try {
      await axios.put(`https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes/${id}`, updatedData);

      toast.success('Solicitação atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setShowEditModal(false);
      fetchSolicitacoes();
    } catch (error) {
      console.error('Erro ao atualizar a solicitação:', error);
    }
  };

  const confirmarSolicitacao = async (id) => {
    try {
      await axios.post(`https://sisger-homo-telesaude.saude.pe.gov.br/api/acaoSolicitacao`, {
        idSolicitacao: id,
        acao: 'confirmar'
      });

      toast.success('Confirmação realizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      
      fetchSolicitacoes();
    } catch (error) {
      console.error('Erro ao confirmar solicitação:', error);
    }
  };

  const cancelarSolicitacao = async (id) => {
    try {
      await axios.post(`https://sisger-homo-telesaude.saude.pe.gov.br/api/acaoSolicitacao`, {
        idSolicitacao: id,
        acao: 'cancelar'
      });

      toast.info('Cancelamento realizado!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      
      fetchSolicitacoes();
    } catch (error) {
      console.error('Erro ao cancelar solicitação:', error);
    }
  };

  useEffect(() => {
    const fetchSolicitacoes = async () => {
      try {
        const response = await axios.get('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes');
        let sortedSolicitacoes = response.data.sort((a, b) => new Date(b.dataReuniao) - new Date(a.dataReuniao));
        if (!showCancelled){
          sortedSolicitacoes = sortedSolicitacoes.filter(solicitacao => solicitacao.status !== 'Cancelada');
        }
        setSolicitacoes(sortedSolicitacoes);
        setFilteredSolicitations([]); 
      } catch (error) {
        console.error('Erro ao buscar solicitações:', error);
      }
    };

    fetchSolicitacoes();
  }, [showCancelled]);

  function toggleModal(solicitacao) {
    setCurrentSolicitacao(solicitacao);
    setShowPostModal(!showPostModal);
  }

  function toggleEditModal(solicitacao) {
    setCurrentSolicitacao(solicitacao);
    setShowEditModal(!showEditModal);
  }

  function truncateText(text, maxLength = 30) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  }

  const totalPages = Math.ceil(solicitacoes.length / PAGE_SIZE);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const displayedSolicitacoes = solicitacoes.slice(startIndex, endIndex);

  return (
    <div>
      <Header />
      <ToastContainer />

      <div className="content">
        <Title name="Solicitações">
          <FiHome size={25} />
        </Title>

        <>
          <Link to="/forms" className="new">
            <FiPlus color="#FFF" size={25} />
            Nova solicitação
          </Link>

          <div className="filter-cancelled">
            <label>
              <input
                type="checkbox"
                checked={showCancelled}
                onChange={() => setShowCancelled(!showCancelled)}
              />
              Mostrar solicitações canceladas
            </label>
          </div>

          <table>
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Título</th>
                <th scope="col">Local</th>
                <th scope="col">Data</th>
                <th scope="col">Link</th>
                <th scope="col">Status</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {displayedSolicitacoes.map((solicitacao) => (
                <tr key={solicitacao.id}>
                  <td data-label="Id">{solicitacao.id}</td>
                  <td data-label="Titulo">{solicitacao.tituloReuniao}</td>
                  <td data-label="Local">{solicitacao.localEvento}</td>
                  <td data-label="Data">{format(new Date(solicitacao.dataReuniao), 'dd/MM/yyyy')}</td>
                  <td data-label="Link">
                    <span className="link-text">{solicitacao.linkReuniao && truncateText(solicitacao.linkReuniao)}</span>
                  </td>
                  <td data-label="Status">
                    <span className="badge" style={{ backgroundColor: getStatusColor(solicitacao.status) }}>
                      {solicitacao.status}
                    </span>
                  </td>
                  <td data-label="#">
                    <button className="action" style={{ backgroundColor: '#3583f6' }} onClick={() => toggleModal(solicitacao)}>
                      <FiSearch color='#FFF' size={17} />
                    </button>
                    <button className="action" style={{ backgroundColor: '#f3a935' }} onClick={() => toggleEditModal(solicitacao)}>
                      <FiEdit2 color='#FFF' size={17} />
                    </button>
                    <button className="action"
                      style={{ backgroundColor: '#5cb85c' }}
                      onClick={() => confirmarSolicitacao(solicitacao.id)}>
                      <FiCheck color='#FFF' size={17} />
                    </button>
                    <button className="action"
                      style={{ backgroundColor: '#e32636' }}
                      onClick={() => cancelarSolicitacao(solicitacao.id)}>
                      <FiXSquare color='#FFF' size={17} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              
          <div className="pagination">
            <button disabled={currentPage === 1} onClick={handlePrevPage}><FiChevronLeft /></button>
            <span>{currentPage} de {totalPages}</span>
            <button disabled={currentPage === totalPages} onClick={handleNextPage}><FiChevronRight /></button>
          </div>

        </>

        {showPostModal && (
          <Modal
            close={() => toggleModal(null)}
            {...currentSolicitacao}
          />
    )}

        {showEditModal && (
          <ModalEdit
          close={() => setShowEditModal(false)}
          solicitacao={currentSolicitacao}
          onSave={(updatedData) => handleEdit(currentSolicitacao.id, updatedData)}
      />
    )}
      </div>
    </div>
  );
}

function getStatusColor(status) {
  switch (status) {
    case 'Confirmada':
      return '#5cb85c';
    case 'Pendente':
      return '#3583f6';
    case 'Cancelada':
      return '#e32636';
    default:
      return '#999';
  }
}

