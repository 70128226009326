import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Header from "../../components/Header";
import Title from "../../components/Title";
import Modal from '../../components/Modal';
import axios from 'axios';

import { FiCheck, FiSearch, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const PAGE_SIZE = 20;

export default function Confirmadas() {
  const [showPostModal, setShowPostModal] = useState(false);
  const [solicitacoesConfirmadas, setSolicitacoesConfirmadas] = useState([]);
  const [currentSolicitacao, setCurrentSolicitacao] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchSolicitacoesConfirmadas = async () => {
      try {
        const response = await axios.get('https://sisger-homo-telesaude.saude.pe.gov.br/api/solicitacoes/confirmadas');
        const sortedSolicitacoes = response.data.sort((a, b) => new Date(b.dataReuniao) - new Date(a.dataReuniao));
        setSolicitacoesConfirmadas(sortedSolicitacoes);
      } catch (error) {
        console.error('Erro ao buscar reuniões confirmadas:', error);
      }
    };

    fetchSolicitacoesConfirmadas();
  }, []);

  function toggleModal(solicitacao) {
      setCurrentSolicitacao(solicitacao);
      setShowPostModal(!showPostModal);
  }

  function truncateText(text, maxLength = 30) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  }

  const totalPages = Math.ceil(solicitacoesConfirmadas.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const displayedSolicitacoes = solicitacoesConfirmadas.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  return (
    <div>
      <Header />

      <div className="content">
        <Title name="Reuniões confirmadas">
          <FiCheck size={25} />
        </Title>

        <table>
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Título</th>
              <th scope="col">Local</th>
              <th scope="col">E-mail</th>
              <th scope="col">Link</th>
              <th scope="col">Status</th>
              <th scope="col">#</th>
            </tr>
          </thead>
          <tbody>
            {displayedSolicitacoes.map((solicitacao) => (
              <tr key={solicitacao.id}>
                <td data-label="Id">{solicitacao.id}</td>
                <td data-label="Titulo">{solicitacao.tituloReuniao}</td>
                <td data-label="Local">{solicitacao.localEvento}</td>
                <td data-label="Data">{format(new Date(solicitacao.dataReuniao), 'dd/MM/yyyy')}</td>
                <td data-label="Link">
                  <span className="link-text">{solicitacao.linkReuniao && truncateText(solicitacao.linkReuniao)}</span>
                </td>
                <td data-label="Status">
                  <span className="badge" style={{ backgroundColor: '#5cb85c' }}>
                    Confirmado
                  </span>
                </td>
                <td data-label="#">
                <button className="action" style={{ backgroundColor: '#3583f6' }} onClick={() => toggleModal(solicitacao)}>
                      <FiSearch color='#FFF' size={17} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button disabled={currentPage === 1} onClick={handlePrevPage}><FiChevronLeft /></button>
          <span>{currentPage} de {totalPages}</span>
          <button disabled={currentPage === totalPages} onClick={handleNextPage}><FiChevronRight /></button>
        </div>

        {showPostModal && (
          <Modal
            close={() => toggleModal(null)}
            {...currentSolicitacao}
          />
        )}
      </div>
    </div>
  );
}
